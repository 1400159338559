import { Domine as GoogleFont } from 'next/font/google';
import Head from 'next/head';

export const domine = GoogleFont({
    weight: ['400', '700'],
    style: ['normal'],
    subsets: ['latin'],
    display: 'swap',
});

export const Domine = () => (
    <Head>
        <style
            key="fontfaces-domine"
            className={domine.className}
            dangerouslySetInnerHTML={{
                __html: `
                :root {
                    --font-family-domine: Domine, ${domine.style.fontFamily}, Helvetica, Arial, sans-serif;
                }`,
            }}
        />
    </Head>
);
